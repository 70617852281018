*{margin: 0; padding: 0; border: 0; outline: 0;}

:root {
    --dark-gray: #272727;
    --medium-gray: #313131;
    --light-gray: #808080;
}

.main{
  display: grid;
  height: 100vh;
  grid-template-columns: 0.15fr 1fr;
  margin: 0px !important;
}

.sidebar
{
  display: list-item;
  gap: 20px;
  align-items: top;
  background-color: grey;
  padding: 15px;
}

.sidebarItem
{
  margin-top: 5px;
  margin-bottom: 15px;
}
.canvas {
  background-color: var(--dark-gray);
  margin: auto;
  padding: 25px;
  display: grid;
  border-radius: 30px;
}

.button-row {
  justify-content: center;
  justify-self: center;
  justify-items: center;
  display: flex;
}

.LEDButtonSquare{
  height:80px;
  width:80px;
  margin: 8px;
  border: 2px solid black;
  border-radius: 5px;
  outline: 0 !important;
  touch-action: manipulation;
}

.LEDButtonSquareOverlay{
  background-color: var(--dark-gray);
  height:66px;
  width:66px;
  margin: 15px;
  outline: 0 !important;
  touch-action: manipulation;
  position: absolute;
  pointer-events: none;
}

.LEDButtonCircle75{
  height:66px;
  width:66px;
  margin: 15px;
  border: 2px solid black;
  border-radius: 33px;
  outline: 0 !important;  
  touch-action: manipulation;
}

.LEDButtonCircle75Overlay{
  background-color: var(--dark-gray);
  height:52px;
  width:52px;
  margin: 22px;
  border-radius: 26px;
  outline: 0 !important;
  touch-action: manipulation;
  position: absolute;
  pointer-events: none;
}

.LEDButtonRectangle{
  height:40px;
  width:80px;
  margin: 8px;
  border: 2px solid black;
  border-radius: 5px;
  outline: 0 !important;
  touch-action: manipulation;
}

.LEDButtonRectangleOverlay{
  background-color: var(--dark-gray);
  height:26px;
  width:66px;
  margin: 15px;
  outline: 0 !important;
  touch-action: manipulation;
  position: absolute;
  pointer-events: none;
}

.LEDButtonCircleLarge{
  height:86px;
  width:86px;
  margin: 3px;
  border: 2px solid black;
  border-radius: 42px;
  outline: 0 !important;
  touch-action: manipulation;
}

.LEDButtonCircleLargeOverlay{
  background-color: var(--dark-gray);
  height:66px;
  width:66px;
  margin: 13px;
  border-radius: 35px;
  outline: 0 !important;
  touch-action: manipulation;
  position: absolute;
  pointer-events: none;
}

.LEDButtonFull{
  height:96px;
  width:96px;
  outline: 0 !important;
  touch-action: manipulation;
}
